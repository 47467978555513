import React, { useEffect, useState } from "react"
import { Link } from "gatsby"

import Frame from "../components/frame"

export default () => {

    const [ location, setLocation ] = useState('');

    useEffect(() => {
        setLocation(window.location.href.replace(/^https?:\/\//, ''));
    }, [])

    return (
        <Frame>
            <h3 className={`text-sans-serif text-bold col-fg`} style={{paddingBottom: "20px"}}>Nothing to see here...</h3>
            <p className={`text-sans-serif col-fg-light`}>There is no page at {location}. If you feel like there should be one, you can <Link to="/about#contact">contact me.</Link> <br/> Otherwise, you can go back <Link to="/">home.</Link></p>
        </Frame>
    )
}